@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/5596735/b38b8bd9-d96f-4bf9-add1-adbd2b08b802.woff2") format("woff2"),
  url("./assets/fonts/5596735/52ed7b2d-8a31-4b18-a1d5-8685608b0889.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/5596764/122f8a1b-fa6b-4246-8d37-911edb4ac556.woff2") format("woff2"),
  url("./assets/fonts/5596764/0f51d8b8-bda2-4557-9b56-0a4e94ab63d9.woff") format("woff");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/5738932/c7dcb253-8dca-459b-aaa9-afef9131ef22.woff2") format("woff2"),
  url("./assets/fonts/5738932/390be235-bf11-42ee-8ba1-2dd10ca6a4ac.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/5596977/c8e0146f-ebf2-4e86-bd3d-047c9a5e5dac.woff2") format("woff2"),
  url("./assets/fonts/5596977/e7972ec4-f161-406c-be4c-f32e884989e7.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}

// Tailwindcss
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --grey-color1: #d3d0cd;
  --grey-color2: #63656a;
  --grey-color3: #404040;
  --grey-color4: #231f20;
  --blue-color: #2ebaf8;
  --blue-color2: #009fe394;
  --blue-color3: #009fe360;
  --blue-color4: #009fe330;
  --green-color1: #4caf50;
  --green-color2: #4caf4f94;
  --green-color3: #4caf4f45;
  --yellow-color1: #ffeb3b;
  --yellow-color2: #ffeb3b94;
  --yellow-color3: #ffeb3b45;
  --amber-color1: #ffcb3b;
  --amber-color2: #ffcb3b94;
  --amber-color3: #ffcb3b45;
  --red-color1: #f44336;
  --red-color2: #f4433694;
  --red-color3: #f4433645;
}

/* You can add global styles to this file, and also import other style files */
.mat-typography {
  font-family: "Proxima Nova", arial, sans-serif !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation: fade-out 1s forwards;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.page-wrapper {
  @apply container mx-auto px-4 mb-8 pt-3 lg:pt-4 lg:pb-12 sm:px-4 md:px-6 lg:px-8 xl:px-10 2xl:px-12;
}

label {
  @apply font-bold text-sm;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Proxima Nova", arial, sans-serif !important;
  font-size: 16px;
}

.dark body {
  @apply bg-grey-600;
}

@import "animate.css";

label {
  font-size: 16px;
}

// Custom Radio Controls
.dtm-radio {
  @apply flex cursor-pointer items-center justify-between rounded-lg border border-gray-100 text-gray-700 dark:text-white dark:border-grey-200 dark:bg-grey-300 p-4 font-medium shadow-sm hover:border-gray-200 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500;
}

//*******************************
//** Material / tailwind fixes **
//*******************************
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-input-element {
  box-shadow: none !important;
}

.sticky {
  position: sticky !important;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  padding: 0;
  border: none;
}

/* Hide increment and decrement controls for all number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.success-snackbar {
  color: black;
  --mdc-snackbar-container-color: #5c7219;

  .mat-simple-snackbar-action {
    color: black;
  }
}

.scrollable-content {
  max-height: calc(100vh - 200px); /* 100vh is 100% of viewport height. 200px is a buffer for dialog title and actions */
  overflow-y: auto; /* Enable vertical scrolling */
}

.mat-snack-bar-container {
  padding: 0 !important;
}
