// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$tams-primary-palette: (
  50: #e0f3fc,
  100: #b3e2f7,
  200: #80cff1,
  300: #4dbceb,
  400: #26ade7,
  500: #009fe3,
  600: #0097e0,
  700: #008ddc,
  800: #0083d8,
  900: #0072d0,
  A100: #f8fcff,
  A200: #c5e2ff,
  A400: #92c8ff,
  A700: #79bbff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$tams-primary-palette-secondary: (
  50: #e8e8e8,
  100: #c6c6c6,
  200: #a0a0a0,
  300: #797979,
  400: #5d5d5d,
  500: #404040,
  600: #3a3a3a,
  700: #323232,
  800: #2a2a2a,
  900: #1c1c1c,
  A100: #f8fcff,
  A200: #c5e2ff,
  A400: #92c8ff,
  A700: #79bbff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$tams-ui-primary: mat.define-palette($tams-primary-palette);
$tams-ui-accent: mat.define-palette($tams-primary-palette-secondary);

// The warn palette is optional (defaults to red).
$tams-ui-warn: mat.define-palette(mat.$red-palette);

$custom-typography: mat.define-typography-config(
  $font-family: "Proxima Nova, arial, sans-serif",
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tams-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $tams-ui-primary,
      accent: $tams-ui-accent,
      warn: $tams-ui-warn,
    ),
    typography: $custom-typography,
  )
);

$tams-ui-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $tams-ui-primary,
      accent: $tams-ui-accent,
      warn: $tams-ui-warn,
    ),
    typography: $custom-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tams-ui-theme);

.dark {
  @include mat.all-component-themes($tams-ui-dark-theme);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Proxima Nova", arial, sans-serif;
}
